import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './landingPage.css';
import 'font-awesome/css/font-awesome.min.css';
import logo from '../../assets/logo.png';
import lang from '../../assets/lang.svg';

function LandingPage() {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    return (
        <div className='navbar'>
            <div className='logo'>
                <a href="/"><img draggable="false" src={ logo } className="logo_img"></img></a>
            </div>
            <button className='mobile-toggle' onClick={handleClick}><i className={click ? "fa fa-times" : "fa fa-bars"}></i></button>
            <nav>
                <ul className={click ? "mobile" : "not-mobile"}>
                    {/*<li>
                        <Link to="/services" className='nav-item nav-item-hover'>
                            Services
                        </Link>
                    </li>
                    <li>
                        <Link to="/marketplace" className='nav-item nav-item-hover'>
                            Marketplace
                        </Link>
                    </li>*/}
                    <li>
                        <Link to="/" className='nav-item nav-item-hover'>
                            Driver Directory
                        </Link>
                    </li>
                    {/*<li>
                        <Link to="/blogs" className='nav-item nav-item-hover'>
                            Blogs
                        </Link>
                    </li>
                    <li>
                        <Link to="/company" className='nav-item nav-item-hover'>
                            Company
                        </Link>
                    </li>
                    <li>
                        <Link to="/" className='nav-item nav-item-hover nav-lang'>
                            <img draggable="false" src={ lang } className='nav-lang-img'/> English
                        </Link>
                    </li>*/}
                    <li>
                        <a href="https://wa.me/+17864607364?text=Hi" target='_blank' className='nav-item nav-button'>
                            Login/SignUp
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default LandingPage;
