import React, { useState } from 'react';
import './hero.css';
import { Link } from 'react-router-dom'
import tractor from '../../assets/tractor.png';
import aadhaar from '../../assets/aadhaar.svg';
import eye from '../../assets/eye.svg';
import live from '../../assets/live.svg';
import truck from '../../assets/truck.svg';
import card from '../../assets/card.svg';
import ibutton from '../../assets/i_button.svg';
import arrow from '../../assets/arrow.svg';

function Hero() {
  return (
      <div>
        <div className='main_hero'>
            <div className='hero_left'>
                <p className='hero_name'>Kishore Kumar</p>
                <p className='hero_des'>Fleet Owner | Transport Contractor</p>
                <p className='hero_subname'>CHITHUR PROPER</p>
                <div className='hero_status'>
                    <img src={ aadhaar } className='aadhaar' />
                    <p className='verify'>AADHAAR</p>
                    <img src={ eye } className='eye' className='eye'/>
                    <p className='visits'>4 VISITS</p>
                </div>
                <div className='hero_tabs'>
                    <p>Load & Lorry Info</p>
                    <p>Operating Routes</p>
                    <p>Suggested Profiles</p>
                </div>
            </div>
            <div className='hero_right'>
                <img src={ tractor } className='hero_img' />
                <div className='hero_active'></div>
            </div>
        </div>
        <div className='active_status'>
            <div className='active_card'>
                <div className='active_card_hero'>
                    <div className='active_card_hero_left'>
                        <p className='active_loads'>Active Loads</p>
                        <img src={ live } className='aadhaar' />
                        <p className='active_live'>LIVE</p>
                    </div>
                    <div className='active_card_hero_right'>
                        <Link to='/' className='arrow'><img src={ arrow } className='arrow_prev'/></Link>
                        <Link to='/' className='arrow'><img src={ arrow } className='arrow_next'/></Link>
                    </div>
                </div>
                <div className='active_des'>
                    <p className='active_des_name'>Steel</p>
                    <p className='active_des_subname'>Chittoor / AP - Chittoor / TN</p>
                    <p className='active_des_weight'><img src={ truck } className='truck_img'/>&nbsp; 25 Tonne(s) Hyva</p>
                    <p className='active_des_bill'><img src={ card } className='card_img' />&nbsp;&nbsp; To be Billed</p>
                    <Link to='/' className='active_des_remark'>View ODC & remarks&nbsp;<img src={ ibutton } className='ibutton_img' /></Link>
                    <p className='bid'>Bid Now</p>
                </div>
            </div>
        </div>
      </div>
  );
}

export default Hero;
