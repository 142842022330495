import React, { useState } from 'react';
import './driverCard.css';
import Modal from 'react-modal'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'axios';
import eye_view from '../../assets/eye_view.svg';
import whatsapp from '../../assets/whatsapp.png';

function DriverCard({photo, views, rating, exp, location, name, skill, id}) {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const url = 'https://admin.signo.in/api/online_driver_directory_send_whatsapp/';
    const [info, setInfo] = useState({
        phone_number: '',
        id: id
    })
    function modalSubmit(e) {
        e.preventDefault();
        Axios.post( url, {
            phone_number: info.phone_number,
            id: info.id
        }).then(res => {
            const status = res.data['status'];
            setModalIsOpen(false);
            toast( status , {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true
                });
        })
    }
    function handle(e) {
        const newinfo = {...info}
        newinfo[e.target.id] = e.target.value
        setInfo(newinfo)
    }

    return (
        <div>
            <div className='driver_card'>
                <div className='driver_card_hero'>
                    <div className='driver_card_img'>
                        <img src={ photo } />
                        <div className='driver_card_active'></div>
                    </div>
                    <div className='driver_card_stats'>
                        <p><img src={ eye_view } /> { views } views</p>
                        <p><img src={ eye_view } /> { rating } rating</p>
                        <p><img src={ eye_view } /> { exp } experience</p>
                    </div>               
                </div>
                <p className='driver_card_location'>{ location }</p>
                <p className='driver_card_name'>{ name }</p>
                <p className='driver_card_role'>{ skill }</p>
                <div className='driver_card_link'>
                    <p className='driver_card_link_profile'>View Profile</p>
                    <p className='driver_card_link_contact'  onClick={()=>setModalIsOpen(true)}>Get Contact Details</p>
                </div>
            </div>
            <Modal className='modal-style' isOpen={modalIsOpen} onRequestClose={()=>setModalIsOpen(false)}
            style={{
                overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'black',
                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                zIndex: '999'
                },
                content: {
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                width: '30vmax',
                height: '20vmax',
                background: '#FFFFFF',
                overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                outline: 'none'
                }
            }}>
                <div className='modal_content'>
                    <div className='modal_hero'>
                        <p>Login / Signup</p>
                        <Link to='/' className='arrow modal_close' onClick={()=>setModalIsOpen(false)}>X</Link>
                    </div>
                    <p className='modal_title'>Enter your mobile number</p>
                    <form onSubmit={(e) => modalSubmit(e)}>
                        <input onChange={(e) => handle(e)} id='phone_number' placeholder='9876543210' type='tel' maxLength='10' className='modal_input' required></input>
                        <img src={ whatsapp } className='whatsapp_updates'/>
                        <button className='driver_card_link_profile modal_submit'>Submit</button>
                    </form>
                </div>
            </Modal>
        </div>
    );
}

export default DriverCard;