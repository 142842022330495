import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import './footer.css';
import logo from '../../assets/logo.png';
import google_play from '../../assets/google_play.png';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { FaTwitterSquare } from 'react-icons/fa';
import { BsYoutube } from 'react-icons/bs';

function Footer() {
    return (
        <div className='footer'>
            <div className='brand_img'>
                <p><img src={ logo } className='logo_img'/>SIGNO</p>
                <img src={ google_play } className='play_img'/>
            </div>
            <div className='company_details'>
                <p className='footer_title'>Company</p>
                <p className='company_details_info footer_subtitle'>© 2020 by Signo. All Rights Reserved.</p>
                <p className='company_details_info'>Signodrive Logistics Pvt Ltd. is an Indian corporation and conducts business throughout India. Signodrive Logistics Pvt Ltd is the holder registered trademark for the name Signo within India. Signo is not affiliated with any trucking company of the same name located in India. </p>
            </div>
            <div className='social'>
                <p className='footer_title'>Social</p>
                <div className='social_links'>
                    <a href="https://www.facebook.com/Signodrive/"><FaFacebookSquare style={{color: '#000'}} size={25} /> Facebook</a>
                    <a href="https://twitter.com/signodrive"><FaTwitterSquare style={{color: '#000'}} size={25} /> Twitter</a>
                    <a href="https://www.linkedin.com/company/signo-drive/"><FaLinkedin style={{color: '#000'}} size={25} /> LinkedIn</a>
                    <a href="#"><BsYoutube style={{color: '#000'}} size={25} /> Youtube</a>
                </div>
            </div>
            <div className='footer_contact'>
                <p className='footer_title'>Contact Us</p>
                <p className='head_office'>HEAD OFFICE</p>
                <p className='address'>B-61, Sector-67, Noida,</p>
                <p className='address'>Uttar Pradesh 201014</p>
                <p className='address'>info@signo.in</p>
                <p className='address'>Tel: +91 7428523569</p>
            </div>
        </div>
    );
}

export default Footer;