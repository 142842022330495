import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom'
import './driver_directory.css';
import Radio from '@mui/material/Radio';
import plus from '../../assets/plus.svg';
import arrow from '../../assets/arrow.svg';
import DriverCard from '../driverCard';
import Loader from '../loader';
import stateData from '../../stateData.json';
import cityData from '../../cityData.json';

function DriverDirectory() {
    const [destination, UpdateDestination] = useState('India');
    const [pageNumber, newPageNumber] = useState(1);

    const [filteredData, setFilteredData] = useState([]);
    const handleFilter = (event) => {
        const wordEntered = event.target.value
        const newFilter = stateData.filter((value) => {
            return value.name.toLowerCase().includes(wordEntered.toLowerCase());
        });
        if(wordEntered === '') {
            setFilteredData([])
        } else {
            setFilteredData(newFilter)
        }
    }

    const [cityFilteredData, setCityFilteredData] = useState([]);
    const handleCityFilter = (event) => {
        const wordEntered = event.target.value
        const newFilter = cityData.filter((value) => {
            return value.name.toLowerCase().includes(wordEntered.toLowerCase());
        });
        if(wordEntered === '') {
            setCityFilteredData([])
        } else {
            setCityFilteredData(newFilter)
        }
    }

    const [selectedValue, setSelectedValue] = useState('Truck Driver');
    const handleChange = (event) => {
      setSelectedValue(event.target.value);
      const newType = {...user};
      newType['page'] = '1'
      newType['skill'] = event.target.value;
      updateUser(newType);
      getDirectory(newType);
    };

    const changeDefault = (e) => {
        UpdateDestination(e.target.id);
        const newDefault = {...user};
        newDefault['page'] = '1';
        newDefault['location'] = e.target.id;
        updateUser(newDefault);
        setFilteredData([]);
        setCityFilteredData([]);
        getDirectory(newDefault);
    }
    
    const [loading, setLoading] = useState(false);
    const[user, updateUser] = useState({
        skill: "Truck Driver",
        page: "1",
        location: "India"
    });
    const [driverData, setDriverData] = useState([]);
    const url = 'https://admin.signo.in/api/fetch_online_driver_directory/';

    function decreaseCount() {
        newPageNumber(Math.max(1, pageNumber-1));
        const newDriverData = {...user};
        newDriverData['page'] = JSON.stringify(pageNumber);
        updateUser(newDriverData);
        if(pageNumber !== 1) {
            getDirectory(newDriverData);
        }
    }
    function increaseCount() {
        newPageNumber(Math.min(pageNumber+1, 5));
        const newDriverData = {...user};
        newDriverData['page'] = JSON.stringify(pageNumber);
        updateUser(newDriverData);
        if(pageNumber !== 5) {
            getDirectory(newDriverData);
        }
    }
    function getDirectory(newUser) {
        console.log(newUser);
        Axios.post(url, newUser).then((res)=>{
            setLoading(true);
            const data = res.data;
            var temp = [];
            for(var i in data) {
                temp.push(data [i]);
            }
            setDriverData(temp);
        });
    }
    useEffect(() => {
        getDirectory(user);
    }, [])
    
    return (
      <div>
        <div className="driver_hero">
          <p className="driver_hero_title">
            India's #1 Online Driver Directory
          </p>
          <p className="driver_hero_subtitle">
            Connect with Driving License & Aadhaar Verified Driver
          </p>
        </div>
        <div className="search_engine">
          <div className="driver_search">
            <p className="driver_search_title">Search Drivers</p>
            <span className="radio_button">
              <Radio
                checked={selectedValue === "Truck Driver"}
                onChange={handleChange}
                value="Truck Driver"
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
              />
              <p>Truck Driver</p>
            </span>
            <span className="radio_button">
              <Radio
                checked={selectedValue === "Hazmat/Trailer Driver"}
                onChange={handleChange}
                value="Hazmat/Trailer Driver"
                name="radio-buttons"
                inputProps={{ "aria-label": "B" }}
              />
              <p>Hazmat/Trailer Driver</p>
            </span>
          </div>
          <div className="place_search">
            <div className="city_search">
              <p>City</p>
              <input
                onChange={handleCityFilter}
                className="city_input"
                type="search"
                placeholder="Search"
              ></input>
            </div>
            <div className="state_search">
              <p>State</p>
              <div className="select_state">
                <p
                  className="default_state"
                  id="Maharashtra"
                  onClick={(e) => changeDefault(e)}
                >
                  Maharashtra <img src={plus} />
                </p>
                <p
                  className="default_state"
                  id="Gujarat"
                  onClick={(e) => changeDefault(e)}
                >
                  Gujarat <img src={plus} />
                </p>
                <p
                  className="default_state"
                  id="Delhi"
                  onClick={(e) => changeDefault(e)}
                >
                  Delhi <img src={plus} />
                </p>
                <p
                  className="default_state"
                  id="Karnataka"
                  onClick={(e) => changeDefault(e)}
                >
                  Karnataka <img src={plus} />
                </p>
                <input
                  onChange={handleFilter}
                  className="select_state_search search expandright"
                  type="search"
                  placeholder="Search"
                ></input>
              </div>
            </div>
          </div>
          {filteredData.length != 0 && (
            <div className="search_results">
              {filteredData.slice(0, 5).map((value, name) => {
                return (
                  <div
                    className="search_result_item"
                    id={value.name}
                    onClick={(e) => changeDefault(e)}
                  >
                    {value.name}
                  </div>
                );
              })}
            </div>
          )}
          {cityFilteredData.length != 0 && (
            <div className="search_results city_search_results">
              {cityFilteredData.slice(0, 5).map((value, name) => {
                return (
                  <div
                    className="search_result_item"
                    id={value.name}
                    onClick={(e) => changeDefault(e)}
                  >
                    {value.name}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="driver_results_hero">
          <div className="driver_results_left">
            <p>Top Drivers of Signo in {destination}</p>
          </div>
          <div className="driver_results_right">
            <p>Page {pageNumber}</p>
            <Link to="/" onClick={() => decreaseCount()} className="arrow">
              <img src={arrow} className="arrow_prev" />
            </Link>
            <Link to="/" onClick={() => increaseCount()} className="arrow">
              <img src={arrow} className="arrow_next" />
            </Link>
          </div>
        </div>
        <div className="driver_results">
          {loading ? (
            driverData.map((counter) => {
              return (
                <DriverCard
                  photo={counter.photo}
                  views={counter.views}
                  rating={counter.rating}
                  exp={counter.experience}
                  location={counter.location}
                  name={counter.name}
                  skill={counter.skill}
                  id={counter.id}
                />
              );
            })
          ) : (
            <Loader />
          )}
        </div>
        <div className="pagination">
          <Link to="/" onClick={() => decreaseCount()} className="arrow">
            <img src={arrow} className="arrow_prev" />
          </Link>
          <Link to="/" onClick={() => increaseCount()} className="arrow">
            <img src={arrow} className="arrow_next" />
          </Link>
        </div>
      </div>
    );
}

export default DriverDirectory;