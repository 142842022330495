import './App.css';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LandingPage from './components/landingPage';
import Hero from './components/hero_sec';
import DriverDirectory from './components/driver_directory';
import Footer from './components/footer';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <LandingPage />
      <Routes>
        <Route path="/profile" element={<Hero />} ></Route>
        <Route path='/' element={<DriverDirectory />} ></Route>
      </Routes>
      <Footer />
      <ToastContainer style={{fontSize: "8rem"}}/>
    </Router>
  );
}

export default App;
